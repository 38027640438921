<template>
<div class="hinweis card">
  <div class="card-content">
    <span class="card-title">Hinweis</span>
    <p>Für ihr Konto wurde bereits ein Sponsoring erfasst. Bei Änderungen zu Ihren gemachten
      Eingaben wenden Sie sich bitte an
      <a href="mailto:sponsorenerfassung@lions-emmerich-rees.de">sponsorenerfassung@lions-emmerich-rees.de</a>.</p>
  </div>
</div>
</template>

<script>
export default {
  name: 'Hinweis',
};
</script>

<style scoped>

</style>
